import { useState, useCallback, useMemo, useContext } from "react"
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Dropdown, DropdownTrigger, DropdownMenu, DropdownItem, Spinner } from "@nextui-org/react";
import { collapseWhiteSpace } from "collapse-white-space"
import { httpsCallable } from "firebase/functions";
import { getBoolean } from "firebase/remote-config";

import { t } from "cvpop-localization-sdk"
import { renderCvPreview } from "cvpop-utility-sdk";

import { functions, remoteConfig } from "../../../controllers/firebase";
import { base64ToBlob, downloadBlob, errorHandler, goToPaywall } from "../../../controllers/app";
import { useColorScheme } from "../../../controllers/hooks";
import { AlertContext } from "../../../contexts/AlertContext";

import WebIcon from "../../atoms/WebIcon";


const WebAICVButton = () => {

    // ------------------------------------------------------------------------------------------------------------------------
    const { cv } = useSelector(state => state.cvReducer)
    const { user, userPro } = useSelector(state => state.userReducer)
    const { offering } = useSelector(state => state.appReducer)
    const { platform } = useParams()
    const { alert } = useContext(AlertContext)

    // ------------------------------------------------------------------------------------------------------------------------
    const n = useNavigate()
    const cs = useColorScheme()

    // ------------------------------------------------------------------------------------------------------------------------
    const [loading, setLoading] = useState(false)

    // ------------------------------------------------------------------------------------------------------------------------
    const api = useMemo(() => httpsCallable(functions, 'getcvv2'), []);
    const fOn = useMemo(() => (getBoolean(remoteConfig, "docxExport")), [])

    // ------------------------------------------------------------------------------------------------------
    return (
        <Dropdown
            backdrop={"blur"}
            placement="right"
            className={`${cs} text-foreground`}
        >
            <DropdownTrigger>
                <div className="flex flex-col items-center">
                    <span className="material-symbols-outlined">smart_toy</span>
                    <span className="text-xs">{t("aiAssistant")}</span>
                </div>
            </DropdownTrigger>
            <DropdownMenu aria-label="Static Actions" color={"primary"} onAction={k => n(`/app/${platform}/cv/${k}`)}>
                <DropdownItem
                    key="analysis"
                    title={t("analyze")}
                    endContent={<WebIcon name={"scan"} />}
                />
                <DropdownItem
                    key="questions"
                    title={t("questionsAndAnswers")}
                    endContent={<WebIcon name={"question_mark"} />}
                />
            </DropdownMenu>
        </Dropdown>
    )
}

export default WebAICVButton