
module.exports = {
    header: `
        <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" align="center" class="r3-o" style="table-layout: fixed; width: 100%;">
            <tr>
                <td class="r4-i" style="padding-bottom: 20px; padding-top: 20px;">
                    <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                        <tr>
                            <th width="100%" valign="top" class="r5-c" style="font-weight: normal;">
                                <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r6-o" style="table-layout: fixed; width: 100%;">
                                    <tr>
                                        <td valign="top" class="r7-i" style="padding-left: 10px; padding-right: 10px;">
                                            <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                <tr>
                                                    <td class="r8-c" align="left">
                                                        <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="200" class="r9-o" style="table-layout: fixed; width: 200px;">
                                                            <tr>
                                                                <td style="font-size: 0px; line-height: 0px;"> <a href="https://cvpop.com" target="_blank" style="color: #007aff; text-decoration: underline;"> <img src="https://img.mailinblue.com/2850542/images/content_library/original/664ca9a1217cebb45b7f89e9.png" width="200" title="CVpop" border="0" style="display: block; width: 100%;"></a> </td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </table>
                            </th>
                        </tr>
                    </table>
                </td>
            </tr>
        </table>
        <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" align="center" class="r3-o" style="table-layout: fixed; width: 100%;">
            <tr>
                <td class="r10-i">
                    <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                        <tr>
                            <th width="100%" valign="top" class="r5-c" style="font-weight: normal;">
                                <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r6-o" style="table-layout: fixed; width: 100%;">
                                    <tr>
                                        <td valign="top" class="r7-i" style="padding-left: 15px; padding-right: 15px;">
                                            <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                <tr>
                                                    <td class="r11-c" align="center" style="padding-bottom: 10px; padding-top: 10px;">
                                                        <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation" height="1" style="border-top-style: solid; background-clip: border-box; border-top-color: #ebebeb; border-top-width: 1px; font-size: 1px; line-height: 1px;">
                                                            <tr>
                                                                <td height="0" style="font-size: 0px; line-height: 0px;">­</td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </table>
                            </th>
                        </tr>
                    </table>
                </td>
            </tr>
        </table>
`
}