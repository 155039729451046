const { urls, cvPickerItems } = require("cvpop-constants-sdk");

const _ = require("lodash")

// Shared functions
const isProUser = (user = {}) => (["PRO", "SUBSCRIBED"].includes(user?.plan));

module.exports = {
    // ------------------------------------------------------------------------------------------------------
    isProUser: isProUser,

    isAmsUser: (user = {}) => ((user || {}).isAms) && !(user || {}).isMarketing,

    isEligibleForCvPhotoUser: userCountry => !["US", "GB", "CA", "AU", "IE", "NL", "SE", "DK", "FI", "NO"].includes(userCountry),

    isEligibleForAdsUser: (user = {}) => {
        if (isProUser(user) || user?.isTA || !user?.isOnboarded) return false // never ready in this case
        const adTime = parseInt(user?.lastInterstitialTime || 0);
        return (((new Date().getTime() - adTime) / 1000) > 180) // each 3 min
    },

    isEligibleForPeriodicOffer: (user = {}) => {
        if (isProUser(user) || user?.isTA) return false // never ready in this case
        const adTime = parseInt(user?.lastPaywallTime || 0);
        return (((new Date().getTime() - adTime) / 1000) > 3600) // each 1h
    },

    isEligibleForAdsOfferUser: (user = {}) => (user?.adsClosedCount >= 2),

    isEligibleForOnboardingOfferUser: (user = {}) => (new Date().getTime() < (user?.lastOnboardOfferTime || 0)),

    isEligibleForGiftUser: (user = {}) => (user && !user.hasGetGift && ["it"].includes(user.language)),

    // ------------------------------------------------------------------------------------------------------
    getNewUserData: (fbuser = {}, language = "", countryCode = "", platform = "", osVersion = "") => {

        const { isAnonymous = false, email = "" } = fbuser
        const now = new Date().getTime();

        return {
            language: cvPickerItems.labelLanguage().find(o => o.code === (language || "en")) ? language : "en",
            countryCode,
            isAnonymous,
            email,
            plan: 'FREE',
            referalCodes: [],
            lastAskRateTime: null,
            updatePlanDate: null,
            lastInterstitialTime: now - (6 * 60 * 1000), // 6 minute without ads
            lastRewardedTime: null,
            platform,
            osVersion,
            lastPaywallTime: 0, // 5minutes to show periodic paywall
            introChoices: { goal: "", seniority: "", timing: "", personality: "", color: "", cvLayout: "" },
            consents: {
                adsConsent: false, adsConsentTime: now,
                marketingConsent: false, marketingConsentTime: now,
                termsAcceptance: true, termsAcceptanceTime: now,
            }
        }

    },

    getAvatarUser: (user = {}, platform = "") => {
        const { avatar } = user || {}
        if (!avatar) return null
        const uri = `data:image/png;base64,${avatar}`
        return ["ios", "android"].includes(platform) ? { uri } : uri
    },


    getUnsubcribeUserUrl: ({ platform, planSubscriptionProvider, planSubscriptionManageUrl, planSubscriptionStore }) => {

        if (planSubscriptionProvider === "LEMON_SQUEEZY") return (planSubscriptionManageUrl || urls.subscription.web)
        if (planSubscriptionStore === "APP_STORE") return urls.subscription.ios
        if (planSubscriptionStore === "PLAY_STORE") return urls.subscription.android
        return urls.subscription[platform] || urls.subscription.web

    },
}
