const { I18n, t } = require("cvpop-localization-sdk")

const { head } = require("../partials/head");
const { header } = require("../partials/header");
const { footer } = require("../partials/footer");

module.exports = {
    getRecoverCvEmail: ({ locale, url, name }) => {

        I18n.changeLanguage(locale);

        return `
            <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">
            <html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
                
                ${head}
                    
                <body bgcolor="#ffffff" text="#3b3f44" link="#007aff" yahoo="fix" style="background-color: #ffffff;">
                    <table cellspacing="0" cellpadding="0" border="0" role="presentation" class="nl2go-body-table" width="100%" style="background-color: #ffffff; width: 100%;">
                        <tr>
                            <td>
                                <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="600" align="center" class="r0-o" style="table-layout: fixed; width: 600px;">
                                    <tr>
                                        <td valign="top" class="r1-i" style="background-color: #ffffff;">

                                            ${header}
                                            
                                            <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" align="center" class="r3-o" style="table-layout: fixed; width: 100%;">
                                                <tr>
                                                    <th width="100%" valign="top" class="r5-c" style="font-weight: normal;">
                                                        <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r6-o" style="table-layout: fixed; width: 100%;">
                                                            <tr>
                                                                <td valign="top" class="r7-i" style="padding-left: 10px; padding-right: 10px;">
                                                                    <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                                        <tr>
                                                                            <td class="r12-c nl2go-default-textstyle" align="left" style="color: #3b3f44; font-family: Verdana; font-size: 16px; line-height: 1.5; word-break: break-word; padding-bottom: 15px; padding-top: 15px; text-align: left; valign: top;">
                                                                                <div>
                                                                                    <p style="margin: 0;">${t("hello")}${name ? " " + name : ""},</p>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td class="r12-c nl2go-default-textstyle" align="left" style="color: #3b3f44; font-family: Verdana; font-size: 16px; line-height: 1.5; word-break: break-word; padding-bottom: 15px; padding-top: 15px; text-align: left; valign: top;">
                                                                                <div>
                                                                                    <p style="margin: 0;">${t("emailRecoverCvBody")}</p>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td class="r12-c nl2go-default-textstyle" align="left" style="color: #3b3f44; font-family: Verdana; font-size: 16px; line-height: 1.5; word-break: break-word; padding-bottom: 15px; padding-top: 15px; text-align: left; valign: top;">
                                                                                <div>
                                                                                    <p style="margin: 0;">${t("emailRecoverCvFooter")}</p>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td class="r13-c" align="left">
                                                                                <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="290" class="r14-o" style="table-layout: fixed; width: 290px;">
                                                                                    <tr class="nl2go-responsive-hide">
                                                                                        <td height="15" style="font-size: 15px; line-height: 15px;">­</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td height="18" align="center" valign="top" class="r15-i nl2go-default-textstyle" style="color: #3b3f44; font-family: Verdana; font-size: 16px; line-height: 1.5; word-break: break-word;">
                                                                                            <!--[if mso]> 
                                                                                            <v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" href="${url}" style="v-text-anchor:middle; height: 41px; width: 289px;" arcsize="29%" fillcolor="#007aff" strokecolor="#007aff" strokeweight="1px" data-btn="1">
                                                                                            <w:anchorlock> </w:anchorlock>
                                                                                            <v:textbox inset="0,0,0,0">
                                                                                                <div style="display:none;">
                                                                                                    <center class="default-button"><span><strong>${t("continue")}</strong></span></center>
                                                                                                </div>
                                                                                            </v:textbox>
                                                                                            </v:roundrect>
                                                                                            <![endif]-->  <!--[if !mso]><!-- --> <a href="${url}" class="r16-r default-button" target="_blank" title="{{params.btnTitle}}" data-btn="1" style="font-style: normal; font-weight: normal; line-height: 1.15; text-decoration: none; word-break: break-word; border-style: solid; word-wrap: break-word; display: block; -webkit-text-size-adjust: none; background-color: #007aff; border-color: #007aff; border-radius: 12px; border-width: 0px; color: #ffffff; font-family: Verdana; font-size: 16px; height: 18px; mso-hide: all; padding-bottom: 12px; padding-left: 5px; padding-right: 5px; padding-top: 12px; width: 280px;"> <span><strong>${t("continue")}</strong></span></a> <!--<![endif]--> 
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr class="nl2go-responsive-hide">
                                                                                        <td height="15" style="font-size: 15px; line-height: 15px;">­</td>
                                                                                    </tr>
                                                                                </table>
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </th>
                                                </tr>
                                            </table>

                                            ${footer}
                                        
                                        </td>
                                    </tr>
                                </table>
                                </td>
                            </tr>
                        </table>
                </body>
            </html>
        `
    }
}

