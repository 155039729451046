module.exports = {
    footer: `
        <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" align="center" class="r3-o" style="table-layout: fixed; width: 100%;">
            <tr>
                <td class="r10-i">
                    <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                        <tr>
                            <th width="100%" valign="top" class="r5-c" style="font-weight: normal;">
                                <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r6-o" style="table-layout: fixed; width: 100%;">
                                    <tr>
                                        <td valign="top" class="r7-i" style="padding-left: 15px; padding-right: 15px;">
                                            <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                <tr>
                                                    <td class="r11-c" align="center" style="padding-bottom: 10px; padding-top: 10px;">
                                                        <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation" height="1" style="border-top-style: solid; background-clip: border-box; border-top-color: #ebebeb; border-top-width: 1px; font-size: 1px; line-height: 1px;">
                                                            <tr>
                                                                <td height="0" style="font-size: 0px; line-height: 0px;">­</td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </table>
                            </th>
                        </tr>
                    </table>
                </td>
            </tr>
        </table>
        <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" align="center" class="r3-o" style="table-layout: fixed; width: 100%;">
            <tr>
                <td class="r4-i" style="padding-bottom: 20px; padding-top: 20px;">
                    <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                        <tr>
                            <th width="100%" valign="top" class="r5-c" style="font-weight: normal;">
                                <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r6-o" style="table-layout: fixed; width: 100%;">
                                    <tr>
                                        <td valign="top" class="r7-i" style="padding-left: 15px; padding-right: 15px;">
                                            <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                <tr>
                                                    <td class="r13-c" align="left">
                                                        <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r17-o" style="table-layout: fixed; width: 100%;">
                                                            <tr>
                                                                <td align="center" valign="top" class="r18-i nl2go-default-textstyle" style="color: #3b3f44; font-family: Verdana; word-break: break-word; font-size: 18px; line-height: 1.5; padding-top: 15px; text-align: center;">
                                                                    <div>
                                                                        <p style="margin: 0;"><strong>CVpop</strong></p>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="r13-c" align="left">
                                                        <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r17-o" style="table-layout: fixed; width: 100%;">
                                                            <tr>
                                                                <td align="center" valign="top" class="r19-i nl2go-default-textstyle" style="color: #3b3f44; font-family: Verdana; word-break: break-word; font-size: 18px; line-height: 1.5; text-align: center;">
                                                                    <div>
                                                                        <p style="margin: 0; font-size: 14px;">Made with ❤︎ in Italy</p>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="r20-c" align="center">
                                                        <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="570" align="center" class="r3-o" style="table-layout: fixed; width: 570px;">
                                                            <tr>
                                                                <td valign="top">
                                                                    <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                                        <tr>
                                                                            <td class="r20-c" align="center" style="display: inline-block;">
                                                                                <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="570" align="center" class="r3-o" style="table-layout: fixed; width: 570px;">
                                                                                    <tr>
                                                                                        <td class="r21-i" style="padding-left: 217px; padding-right: 217px; padding-top: 5px;">
                                                                                            <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                                                                <tr>
                                                                                                    <th width="24" class="r22-c mobshow resp-table" style="font-weight: normal;">
                                                                                                        <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r23-o" style="table-layout: fixed; width: 100%;">
                                                                                                            <tr>
                                                                                                                <td class="r24-i" style="font-size: 0px; line-height: 0px; padding-bottom: 5px; padding-top: 5px;"> <a href="https://cvpop.com" target="_blank" style="color: #007aff; text-decoration: underline;"> <img src="https://creative-assets.mailinblue.com/editor/social-icons/rounded_colored/website_16px.png" width="16" border="0" style="display: block; width: 100%;"></a> </td>
                                                                                                                <td class="nl2go-responsive-hide" width="8" style="font-size: 0px; line-height: 1px;">­ </td>
                                                                                                            </tr>
                                                                                                        </table>
                                                                                                    </th>
                                                                                                    <th width="24" class="r22-c mobshow resp-table" style="font-weight: normal;">
                                                                                                        <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r23-o" style="table-layout: fixed; width: 100%;">
                                                                                                            <tr>
                                                                                                                <td class="r24-i" style="font-size: 0px; line-height: 0px; padding-bottom: 5px; padding-top: 5px;"> <a href="https://www.linkedin.com/company/cvpop/" target="_blank" style="color: #007aff; text-decoration: underline;"> <img src="https://creative-assets.mailinblue.com/editor/social-icons/rounded_colored/linkedin_16px.png" width="16" border="0" style="display: block; width: 100%;"></a> </td>
                                                                                                                <td class="nl2go-responsive-hide" width="8" style="font-size: 0px; line-height: 1px;">­ </td>
                                                                                                            </tr>
                                                                                                        </table>
                                                                                                    </th>
                                                                                                    <th width="24" class="r22-c mobshow resp-table" style="font-weight: normal;">
                                                                                                        <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r23-o" style="table-layout: fixed; width: 100%;">
                                                                                                            <tr>
                                                                                                                <td class="r24-i" style="font-size: 0px; line-height: 0px; padding-bottom: 5px; padding-top: 5px;"> <a href="https://www.facebook.com/app.cvpop" target="_blank" style="color: #007aff; text-decoration: underline;"> <img src="https://creative-assets.mailinblue.com/editor/social-icons/rounded_colored/facebook_16px.png" width="16" border="0" style="display: block; width: 100%;"></a> </td>
                                                                                                                <td class="nl2go-responsive-hide" width="8" style="font-size: 0px; line-height: 1px;">­ </td>
                                                                                                            </tr>
                                                                                                        </table>
                                                                                                    </th>
                                                                                                    <th width="24" class="r22-c mobshow resp-table" style="font-weight: normal;">
                                                                                                        <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r23-o" style="table-layout: fixed; width: 100%;">
                                                                                                            <tr>
                                                                                                                <td class="r24-i" style="font-size: 0px; line-height: 0px; padding-bottom: 5px; padding-top: 5px;"> <a href="https://www.instagram.com/app.cvpop" target="_blank" style="color: #007aff; text-decoration: underline;"> <img src="https://creative-assets.mailinblue.com/editor/social-icons/rounded_colored/instagram_16px.png" width="16" border="0" style="display: block; width: 100%;"></a> </td>
                                                                                                                <td class="nl2go-responsive-hide" width="8" style="font-size: 0px; line-height: 1px;">­ </td>
                                                                                                            </tr>
                                                                                                        </table>
                                                                                                    </th>
                                                                                                    <th width="24" class="r22-c mobshow resp-table" style="font-weight: normal;">
                                                                                                        <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r23-o" style="table-layout: fixed; width: 100%;">
                                                                                                            <tr>
                                                                                                                <td class="r24-i" style="font-size: 0px; line-height: 0px; padding-bottom: 5px; padding-top: 5px;"> <a href="https://x.com/app_cvpop" target="_blank" style="color: #007aff; text-decoration: underline;"> <img src="https://creative-assets.mailinblue.com/editor/social-icons/rounded_colored/twitter_16px.png" width="16" border="0" style="display: block; width: 100%;"></a> </td>
                                                                                                                <td class="nl2go-responsive-hide" width="8" style="font-size: 0px; line-height: 1px;">­ </td>
                                                                                                            </tr>
                                                                                                        </table>
                                                                                                    </th>
                                                                                                    <th width="16" class="r22-c mobshow resp-table" style="font-weight: normal;">
                                                                                                        <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r25-o" style="table-layout: fixed; width: 100%;">
                                                                                                            <tr>
                                                                                                                <td class="r24-i" style="font-size: 0px; line-height: 0px; padding-bottom: 5px; padding-top: 5px;"> <a href="https://www.youtube.com/channel/UCE1o64SOoxJoIMhXY1zDUkw" target="_blank" style="color: #007aff; text-decoration: underline;"> <img src="https://creative-assets.mailinblue.com/editor/social-icons/rounded_colored/youtube_16px.png" width="16" border="0" style="display: block; width: 100%;"></a> </td>
                                                                                                            </tr>
                                                                                                        </table>
                                                                                                    </th>
                                                                                                </tr>
                                                                                            </table>
                                                                                        </td>
                                                                                    </tr>
                                                                                </table>
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </table>
                            </th>
                        </tr>
                    </table>
                </td>
            </tr>
        </table>
    
    `
}