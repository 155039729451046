const _ = require("lodash")

const { isProUser } = require("./user");

const { getCommonCss } = require("./common/common/common-css");
const { getCommonHeaders } = require("./common/common/common-headers");
const { getBasePatternCss } = require("./common/base-patterns/basepatterns");
const { getBaseImageCss } = require("./common/base-images/baseImage");
const { getTemplateCss } = require("./common/template-styles/template-style");
const { getTemplateBody } = require("./common/template-body/template-body");
const { footer } = require("./common/partials/footer");

const { gerErrorAlertEmail } = require("./emails/common/error-alert");
const { getRecoverCvEmail } = require("./emails/cv/recover-cv-request");
const { getBetaInviteEmail } = require("./emails/user/beta-invite");
const { gerFeatureRequestEmail } = require("./emails/user/feature-request");

module.exports = {
    renderCvPreview: (cvOrig = {}, user = {}, addStyle = "", publicMode = false) => {

        const cv = _.cloneDeep(cvOrig)
        const isPro = isProUser(user)

        // if the user is not PRO, remove items
        if (!isPro && user.canBePro) delete cv.SkillsPassport.LearnerInfo.Identification.Signature
        if (!isPro && user.canBePro) delete cv.SkillsPassport.LearnerInfo.Video
        if (!isPro && user.canBePro) delete cv.SkillsPassport.LearnerInfo.Portfolio
        if (!isPro && user.canBePro) delete cv.SkillsPassport.LearnerInfo.Attachment
        if (!isPro && user.canBePro) {
            for (let i in cv.SkillsPassport.LearnerInfo.Education) delete cv.SkillsPassport.LearnerInfo.Education[i].Organisation.Logo;
            for (let j in cv.SkillsPassport.LearnerInfo.WorkExperience) delete cv.SkillsPassport.LearnerInfo.WorkExperience[j].Employer.Logo;
        }


        const footerTemplate = footer(cv, user)
        const html = `
            <!doctype html>
            <html>
                <head>
                    ${getCommonHeaders(cv)}
                    <style>
                        ${getBasePatternCss(cv)}
                        ${getBaseImageCss(cv)}
                        ${getCommonCss(cv)}
                        ${getTemplateCss(cv)}
                    </style>
                    <style>${addStyle}</style>
                </head>
                <body>
                    ${getTemplateBody(cv)}
                </body>
            </html>
        `
        return { html, footerTemplate };
    },

    renderEmailPreview: (type, data) => {
        if (type === "error-alert")
            return gerErrorAlertEmail(data)
        if (type === "recover-cv-request")
            return getRecoverCvEmail(data)
        if (type === "beta-invite")
            return getBetaInviteEmail(data)
        if (type === "new-user-feature-request")
            return gerFeatureRequestEmail(data)
    }
}