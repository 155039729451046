import React, { useCallback, useMemo, useState, useRef, useContext } from "react";
import { Button } from "@nextui-org/react";
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, useDisclosure } from "@nextui-org/react";
import { Dropdown, DropdownTrigger, DropdownMenu, DropdownItem } from "@nextui-org/react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import { httpsCallable } from "firebase/functions";
import { getBoolean } from "firebase/remote-config";
import { ref, uploadString, getDownloadURL, deleteObject } from "firebase/storage";
import { Cropper } from 'react-advanced-cropper';
import { produce } from "immer";

import Markdown from 'react-markdown'

import { icons } from "cvpop-assets-sdk";
import { getCvImage } from "cvpop-utility-sdk";
import { setCv } from "cvpop-redux-sdk";
import { t } from "cvpop-localization-sdk"

import { functions, remoteConfig, storage } from "../../../../controllers/firebase";
import { imageToBase64, errorHandler } from "../../../../controllers/app";
import { useColorScheme } from "../../../../controllers/hooks";
import { AlertContext } from "../../../../contexts/AlertContext";

import WebHeader from "../../../molecules/views/WebHeader";
import WebIcon from "../../../atoms/WebIcon";
import WebAIAssistantButton from "../../../molecules/buttons/WebAIAssistantButton";



const WebPhoto = () => {

    // ------------------------------------------------------------------------------------------------------
    const { cv } = useSelector(state => state.cvReducer)
    const { user } = useSelector(state => state.userReducer)
    const { platform } = useParams()
    const { alert, confirmDelete } = useContext(AlertContext)

    // ------------------------------------------------------------------------------------------------------
    const d = useDispatch()
    const cs = useColorScheme()
    const inputRef = useRef()
    const cropRef = useRef()
    const cropModal = useDisclosure();
    const eligibilityModal = useDisclosure();

    // ------------------------------------------------------------------------------------------------------
    const [cropImg, setCropImg] = useState("")
    const [loading, setLoading] = useState(false)

    // ------------------------------------------------------------------------------------------------------
    const removeBgApi = useMemo(() => httpsCallable(functions, 'getcvimagewithnobg'), [functions]);

    const featRemBgOn = useMemo(() => (getBoolean(remoteConfig, "cvRemoveUserImgBg")), [remoteConfig])
    const replicateOn = useMemo(() => (getBoolean(remoteConfig, "replicateOn")), [remoteConfig])
    const remBgOn = useMemo(() => featRemBgOn && replicateOn, [featRemBgOn, replicateOn])

    const cvImg = useMemo(() => getCvImage(cv, platform) || icons.avatarcv, [user, cv])
    const photo = useMemo(() => cv.SkillsPassport.LearnerInfo.Identification.Photo, [cv]);
    const hasPhoto = useMemo(() => (photo && photo.Data !== ""), [photo]);

    // ------------------------------------------------------------------------------------------------------
    const removeBg = useCallback(() => {
        setLoading(true)
        const sRef = ref(storage, `temp/bgRem${cv.Metadata.id}.jpg`);
        uploadString(sRef, photo.Data, 'base64', { contentType: 'image/jpg' })
            .then(snap => getDownloadURL(snap.ref))
            .then(url => removeBgApi({ url }))
            .then(({ data }) => {
                const newImg = { "MimeType": "image/jpeg", "Data": data, "BgRemoved": true, "Metadata": [{ "Key": "dimension", "Value": "300x400" }] }
                d(setCv({ cv: produce(cv, d => { d.SkillsPassport.LearnerInfo.Identification.Photo = newImg }) }))
            })
            .catch(e => errorHandler(alert, "err_remove_image_bg", e))
            .finally(() => {
                deleteObject(sRef).catch(e => errorHandler(alert, "err_remove_image_bg_tmp_file", e, true))
                setLoading(false)
            })
    }, [cv])

    const deletePhoto = useCallback(() => {
        d(setCv({ cv: produce(cv, d => { d.SkillsPassport.LearnerInfo.Identification.Photo = { "MimeType": "", "Data": "", "Metadata": [] } }) }))
    }, [cv, d]);

    // ------------------------------------------------------------------------------------------------------
    const renderActionButton = useCallback(() => (
        <Button
            fullWidth
            isLoading={loading}
            className="mt-4"
            color={"primary"}
            onClick={(hasPhoto) ? null : () => {
                inputRef.current.value = ""
                inputRef.current.click()
            }}
        >
            {t(hasPhoto ? "showOptions" : "add")}
        </Button>
    ), [hasPhoto, loading, inputRef])

    // ------------------------------------------------------------------------------------------------------
    return (
        <div>
            <WebHeader title={t("photo")} />
            <div className="flex-col p-5">
                <div className="flex w-full justify-center items-center">
                    <div className="rounded-2xl">
                        <img
                            alt={t("photo")}
                            src={cvImg.uri || cvImg}
                            className={`rounded-2xl object-cover mr-3 border-4 border-primary`}
                            style={{ maxHeight: 350, backgroundColor: "#F2F2F1" }}
                        />
                    </div>
                    <input
                        type="file"
                        className='hidden'
                        accept="image/png,image/jpeg"
                        ref={inputRef}
                        onChange={({ target }) => {
                            if (target.files.length === 0) return
                            if (!["image/png", "image/jpeg"].includes(target.files[0].type)) return alert(t("errTitle"), t("notSupportedTypeFile"))
                            imageToBase64(target.files[0])
                                .then(b64 => {
                                    setCropImg(b64);
                                    cropModal.onOpen();
                                })
                                .catch(e => errorHandler(alert, "err_convert_base64", e))
                        }}
                    />
                </div>
                <p className="mt-4 text-center">{t('photoMsg')}</p>

                {
                    hasPhoto ? (
                        <Dropdown backdrop="blur" className={`${cs} text-foreground`}>
                            <DropdownTrigger>
                                {renderActionButton()}
                            </DropdownTrigger>
                            <DropdownMenu
                                aria-label="Static Actions"
                                color={"primary"}
                                onAction={k => {
                                    if (k === "delete") return confirmDelete().then(deletePhoto).catch(() => { })
                                    if (k === "removeBg") return removeBg()
                                    if (k === "edit") {
                                        inputRef.current.value = ""
                                        inputRef.current.click()
                                    }

                                }}
                            >
                                <DropdownItem key="edit" title={t("edit")} endContent={<WebIcon name={"edit"} />} />
                                {
                                    (remBgOn && !photo.BgRemoved) && (
                                        <DropdownItem key="removeBg" title={t("removeBg")} endContent={<WebIcon name={"background_replace"} />} />
                                    )
                                }
                                <DropdownItem key="delete" title={t("delete")} className="text-danger" color="danger" endContent={<WebIcon name={"delete"} />} />
                            </DropdownMenu>
                        </Dropdown>

                    ) : (renderActionButton())
                }

                <WebAIAssistantButton sectionId={"PHOTO"} color={"secondary"} />

            </div>

            <Modal
                isOpen={cropModal.isOpen}
                onOpenChange={cropModal.onOpenChange}
                hideCloseButton={true}
                backdrop={"blur"}
                className={`${cs} text-foreground`}
            >
                <ModalContent>
                    <ModalHeader className="flex flex-col gap-1">{t("cutImage")}</ModalHeader>

                    <ModalBody>
                        <Cropper ref={cropRef} src={cropImg} aspectRatio={0.75} />
                    </ModalBody>

                    <ModalFooter>
                        <Button color="danger" variant="light" onPress={cropModal.onClose}>
                            {t("cancel")}
                        </Button>
                        <Button
                            color="primary"
                            onPress={() => {
                                if (!cropRef.current) return
                                const croppedImage = cropRef.current.getCanvas().toDataURL("image/jpeg");
                                const newPhoto = { "MimeType": croppedImage.split(',')[0], "Data": croppedImage.split(',')[1], "Metadata": [{ "Key": "dimension", "Value": "300x400" }] }
                                d(setCv({ cv: produce(cv, d => { d.SkillsPassport.LearnerInfo.Identification.Photo = newPhoto }) }));
                                cropModal.onClose()
                            }}
                        >
                            {t("done")}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <Modal
                isOpen={eligibilityModal.isOpen}
                onOpenChange={eligibilityModal.onOpenChange}
                hideCloseButton={true}
                size={"2xl"}
                scrollBehavior={"inside"}
                backdrop={"blur"}
                className={`${cs} text-foreground`}
            >
                <ModalContent>
                    <ModalHeader className="flex flex-col gap-1">{t("photoAnalysis")}</ModalHeader>

                    <ModalBody>
                        <Markdown>{photo?.EligibilityInfo}</Markdown>
                    </ModalBody>

                    <ModalFooter>
                        <Button color="primary" onPress={eligibilityModal.onClose}>
                            {t("close")}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

        </div>

    )
}

export default WebPhoto